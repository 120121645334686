import './search-bar.scss';

class SearchBar {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-searchbar',
            inputAttr: 'input',
            submitAttr: 'submit',
            rootToggleAttr: 'rootToggle'
        };

        this.settings = Object.assign({}, defaults, options);

        this.$searchBar = element;
        this.$searchBarInput = this.$searchBar.querySelector('[' + this.settings.initAttr + '="' + this.settings.inputAttr + '"]');
        this.$searchBarSubmit = this.$searchBar.querySelector('[' + this.settings.initAttr + '="' + this.settings.submitAttr + '"]');
        this.$searchBarRootToggle = document.querySelector('[' + this.settings.initAttr + '="' + this.settings.rootToggleAttr + '"]');

        this.initialize();
    }

    initialize () {
        if (this.$searchBarRootToggle.checked === false) {
            this.toggleTabindices(false);
        }

        this.setEvents();
    }

    toggleTabindices (visible) {
        if (visible === false) {
            this.$searchBarInput.setAttribute('tabindex', -1);
            this.$searchBarSubmit.setAttribute('tabindex', -1);
        } else {
            this.$searchBarInput.removeAttribute('tabindex');
            this.$searchBarSubmit.removeAttribute('tabindex');
        }
    }

    setEvents () {
        this.$searchBarRootToggle.addEventListener('change', () => {
            if (this.$searchBarRootToggle.checked === true) {
                this.toggleTabindices(true);
                this.$searchBarInput.focus();
            } else {
                this.toggleTabindices(false);
            }
        });

        // close navigation on ESC key
        document.addEventListener('keydown', (e) => {
            let isEscape = false;
            if ('key' in e) {
                isEscape = (e.key === 'Escape' || e.key === 'Esc');
            } else {
                isEscape = (e.keyCode === 27);
            }
            if (isEscape && this.$searchBarRootToggle.checked === true) {
                this.$searchBarRootToggle.checked = false;
                this.toggleTabindices(false);
            }
        });
    }
}

export default SearchBar;

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context === document) {
        const $searchBar = document.querySelector('[data-searchbar="root"]');
        if ($searchBar) {
            const searchBarAPI = new SearchBar($searchBar);
            $searchBar.API = searchBarAPI;
        }
    }
});
